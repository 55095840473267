export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/": [6],
		"/(public)/home": [19,[5]],
		"/(app)/studentApp/assistants/[assistantId]": [~7,[2,3]],
		"/(app)/studentApp/backendtest": [~8,[2,3]],
		"/(app)/studentApp/dashboard": [~9,[2,3]],
		"/(app)/studentApp/material": [10,[2,3]],
		"/(app)/studentApp/schedules": [11,[2,3]],
		"/(app)/studentApp/settings": [12,[2,3]],
		"/(app)/teacherApp/assistants/[assistantId]": [~13,[2,4]],
		"/(app)/teacherApp/backendtest": [~14,[2,4]],
		"/(app)/teacherApp/dashboard": [~15,[2,4]],
		"/(app)/teacherApp/material": [16,[2,4]],
		"/(app)/teacherApp/schedules": [17,[2,4]],
		"/(app)/teacherApp/settings": [18,[2,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';